import React from "react"
import Imgix, { Picture, Source, buildURL } from "react-imgix"
import "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"

import styles from "./singleImage.module.scss"

// Should this be its own component?
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const singleImage = props => {
  const image = props.data.image[0]
  const mobileImage = props.data.mobileImage[0]
  const columns = props.data.columnWidth
  const maxImageWidth = columns === "fullBleed" ? 1800 : (columns / 12) * 1344
  const sizes =
    columns === "fullBleed"
      ? // Full bleed is full bleed from mobile up to 1800px
        `(min-width: ${maxImageWidth}px) ${maxImageWidth}px, 100vw`
      : // Column widths max out at their width calculated in maxImageWidth and are full bleed minus the gutter on mobile
        `(min-width: ${maxImageWidth}px) ${maxImageWidth}px, calc(100vw - 2.5rem)`

  return (
    <div
      className={`module module--image ${
        columns === "fullBleed" ? "full-bleed" : ""
      }`}
    >
      <div
        className={`wrapper ${
          columns === "fullBleed" ? "wrapper--full-bleed" : ""
        }`}
      >
        {/* Conditionally add .columns > .column is-${columns}-desktop wrapper */}
        <ConditionalWrapper
          condition={columns !== "fullBleed"}
          wrapper={children => (
            <div className="columns is-centered is-desktop">
              <div className={`column is-${columns}-desktop`}>{children}</div>
            </div>
          )}
        >
          <Picture>
            <Source
              src={image.url}
              htmlAttributes={{ media: "(min-width: 768px)" }}
              sizes={sizes}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
            <Source
              src={mobileImage.url}
              sizes={sizes}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
            <Imgix
              className={`${styles.image} lazyload`}
              src={buildURL(mobileImage.url, { w: 50, blur: 40 })}
              sizes={sizes}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              htmlAttributes={{
                src: buildURL(mobileImage.url, { w: 50, blur: 40 }),
                alt: "",
              }}
            />
          </Picture>
        </ConditionalWrapper>
      </div>
    </div>
  )
}

export default singleImage
